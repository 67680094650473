import axios from 'axios';
import { getApiUrl } from '../config';
import {
  showError,
  showErrorWithButton,
  showSuccess,
} from '../components/alerts/notifications';

export const getUser = async ({ addressMetamask }) => {
  const apiUrl = getApiUrl(`findUser/${addressMetamask}`);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      acepted: 'application/json',
    },
  }).then((response) => response.json());
};
export function logout() {
  fetch('/users/sign_out', {
    // fetch("https://sportex-staging.herokuapp.com/users/sign_out", {
    method: 'DELETE',
  })
    .then(() => {
      window.location.href = '/users/sign_in';
    })
    .catch((errs) => {
      console.error(errs);
    });
}
export const updateUser = async ({ user_id, email, username }) => {
  const apiUrl = getApiUrl(`updateUser/${user_id}`);

  try {
    const res = await axios.put(apiUrl, {
      email,
      username,
    });
    if (res?.status === 200) {
      showSuccess({
        title: 'Message',
        message: res?.data.message,
      });
      return;
    } else if (res?.status === 500) {
      showError({
        title: 'Error',
        message: res?.data.message,
      });
    }
  } catch (error) {
    console.error(error, 'ERROR');
    showErrorWithButton({
      title: 'Error updating user',
      message: error?.response?.data?.error || 'An error occurred',
    });
  }
};

export const updateAvatar = async ({ userId, avatar }) => {
  const apiUrl = getApiUrl(`updateAvatar/${userId}`);
  return axios
    .put(apiUrl, avatar, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(async (res) => {
      showSuccess({
        title: 'Success',
        message: 'image updated successfully',
      });
    })
    .catch((err) => {
      console.error(err, 'ERROR');
      showError({
        title: 'Error',
        message: 'An error occurred while updating the avatar',
      });
    });
};
