import React, { useState } from 'react';

import {
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Collapse,
  Button,
  Img,
  Text,
  Divider,
  Tooltip,
  Center,
} from '@chakra-ui/react';
import IconMetamask from '../../img/Metamask.webp';
import IconCard from '../../img/iconCard.webp';
import { useMetamask } from '../../useContext/MetamaskContext';
import { PaymentCard } from './PaymentMethodSection/PaymentCard/PaymentCard';
import sportXLogo from '../../img/SportxLogoBlack.webp';
import { paymentWithCard, paymentWithMetamask } from '../../services/payment';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoaderBlock } from '../../components/LoaderBlock/LoaderBlock';
import { useApolloClient } from '@apollo/client';
import { ownerAddress } from '../../config';

dayjs.extend(utc);

export const Checkout = () => {
  const nft = JSON.parse(localStorage.getItem('nft_sportx_to_buy'));

  if (!nft) {
    return <Navigate to={'/'} />;
  }
  const client = useApolloClient();
  const defaultSelected = localStorage.getItem('defaultPaymentSporteX');
  const navigate = useNavigate();
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState({
    transaction_id: 'undefined',
    date: 'undefined',
    status: 'undefined',
    purchased_amount: 'undefined',
  });
  const { addressMetamask } = useMetamask();
  const stripe = useStripe();
  const elements = useElements();
  const [selectedDefault, setSelectedDefault] = useState(defaultSelected);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(defaultSelected);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipClick = ({ nft }) => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const isOwnerSeller = nft?.seller?.toLowerCase() === ownerAddress.toLowerCase();

  const handlePaymentWithMetamask = async () => {
    try {
      setIsLoadingTransaction(true);

      const details = await paymentWithMetamask({
        nft,
        addressMetamask,
      });

      if (details) {
        deletedItemCache(nft?.id);

        setPurchaseDetails({
          date: details?.date,
          purchased_amount: details?.purchased_amount,
          status: details?.status,
          transaction_id: details?.transaction_id,
        });

        setPaymentCompleted(true);
        setTimeout(() => {
          clearLocalStorage();
        }, 2000);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setIsLoadingTransaction(false);
    }
  };
  function clearLocalStorage() {
    localStorage.removeItem('nft_sportx_to_buy');
    // navigate('/', { replace: true });
  }
  function handleRedirect() {
    clearLocalStorage();
    navigate('/', { replace: true });
  }

  const onSubmit = async () => {
    if (selectedPayment === 'metamask') {
      handlePaymentWithMetamask();
      return;
    }
    try {
      if (!stripe || !elements) {
        return;
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.error(submitError, 'SUBMIT');
        return;
      }

      setIsLoadingTransaction(true);
      const amountInCents = (nft?.priceInDollars * 100).toFixed(0);
      const details = await paymentWithCard({
        stripe,
        elements,
        addressMetamask,
        amountInCents,
        nft,
      });
      if (details) {
        setPaymentCompleted(true);
        setPurchaseDetails({
          date: details?.date,
          purchased_amount: details?.purchased_amount,
          status: details?.status,
          transaction_id: details?.transaction_id,
        });
        deletedItemCache(nft?.id);
        setTimeout(() => {
          clearLocalStorage();
        }, 2000);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingTransaction(false);
    }
  };
  async function deletedItemCache(id) {
    await client.cache.evict({ id: `Nft:${id}` });
  }
  return (
    <Center p="10px" height={{ base: '1400px', md: '100vh' }}>
      {isLoadingTransaction && <LoaderBlock />}
      <Stack
        rounded="20px"
        boxShadow="md"
        direction={{ base: 'column-reverse', md: 'row' }}
        h={{ base: '1220px', md: '540px' }}
        w={{ base: '100%', md: '860px' }}
        // padding="20px"
        overflow="hidden"
        bgColor="white"
        boxSizing="border-box"
      >
        <>
          {/* STRIPE FORM */}
          <Box
            p="20px"
            onSubmit={onSubmit}
            width={{ base: '100%', md: '490px' }}
            height={{ base: '600px', md: '100%' }}
            style={{
              position: 'relative',
            }}
          >
            {!paymentCompleted ? (
              <>
                <Box h="40px">
                  <Heading fontSize="20px">Select your payment method</Heading>
                </Box>
                <Box height="calc(100% - 150px)" overflowY="scroll">
                  <PaymentCard
                    onSelect={setSelectedPayment}
                    nameSelection={'metamask'}
                    isSelected={selectedPayment === 'metamask'}
                    icon={IconMetamask}
                    setSelectedDefault={setSelectedDefault}
                    selectedDefault={selectedDefault}
                    title="Buy with Metamask"
                  ></PaymentCard>
                  { isOwnerSeller ? (<PaymentCard
                      isSelected={selectedPayment === 'card'}
                      onSelect={setSelectedPayment}
                      nameSelection={'card'}
                      icon={IconCard}
                      setSelectedDefault={setSelectedDefault}
                      selectedDefault={selectedDefault}
                      title="Pay with credit/debit card" ></PaymentCard>) : null }
                  <Collapse w="100%" in={selectedPayment === 'card'}>
                    {/* <CardElement /> */}
                    <Box padding="10px">
                      <LinkAuthenticationElement />
                      <AddressElement
                        options={{
                          blockPoBox: true,
                          autocomplete: {
                            mode: 'disabled',
                          },
                          mode: 'billing',
                          fields: {
                            phone: 'always',
                          },
                        }}
                      />
                      <PaymentElement options={{}} />
                      {/* <CardNumberElement /> */}
                      {/* <ExpressCheckoutElement options={{}} /> */}
                    </Box>
                  </Collapse>
                </Box>
                <Box
                  position="relative"
                  bottom="20px"
                  h="100px"
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                >
                  <Button
                    onClick={handleRedirect}
                    w="100%"
                    isLoading={isLoadingTransaction}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={isLoadingTransaction}
                    w="100%"
                    isDisabled={paymentCompleted}
                    type="button"
                    bgColor="#3377FE"
                    color="white"
                    _hover={{ bgColor: '#4684FF' }}
                    onClick={() => onSubmit()}
                  >
                    {' '}
                    Confirm
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  padding="0 30px"
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  height="100%"
                >
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Img
                      w="150px"
                      h="50px"
                      objectFit={'none'}
                      src={sportXLogo}
                    />
                    <Heading fontSize="20px">
                      Your payment has been processed!
                    </Heading>
                    <Text>Thanks for your purchase</Text>
                  </Box>

                  <Box width={{ base: '100%', md: '100%' }}>
                    {[
                      {
                        label: 'Transaction number',
                        value: purchaseDetails?.transaction_id,
                      },
                      { label: 'Date', value: purchaseDetails?.date },
                      { label: 'Estatus', value: purchaseDetails?.status },
                      {
                        label: 'Amount',
                        value: purchaseDetails?.purchased_amount,
                      },
                      {
                        label: 'Payment method',
                        value:
                          selectedPayment === 'metamask' ? 'Metamask' : 'Card',
                      },
                    ].map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Text w="50%">{item.label}</Text>
                        <Text w="50%">{item.value}</Text>
                      </Box>
                    ))}
                  </Box>
                  <Center w="100%">
                    <Button
                      color="white"
                      _hover={{ bgColor: '#4684FF' }}
                      bgColor="#3377FE"
                      onClick={handleRedirect}
                    >
                      Continue shopping
                    </Button>
                  </Center>
                </Box>
              </>
            )}
          </Box>
          {/* ORDER SUMMARY */}
          {/* <Center w={{ base: '100%', md: '400px' }} h="100%"> */}
          <Box p="20px">
            <Box h="50px">
              <Heading textAlign="left" fontSize="20px" width="100%">
                Order summary
              </Heading>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                width="300px"
                height="430px"
                bgColor="white"
                border="1px solid black"
                p="10px"
                rounded="20px"
              >
                <Box
                  bgColor="#E7EEFF"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  rounded="20px"
                  width="100%"
                  height="240px"
                  overflow="hidden"
                >
                  <Img
                    width="290px"
                    height="290px"
                    objectFit="cover"
                    src={nft?.image}
                  />
                </Box>
                <Box p="20px">
                  <Box display="flex" justifyContent="space-between">
                    <Tooltip
                      isOpen={isTooltipOpen}
                      label={nft?.name}
                      placement="top"
                      hasArrow
                    >
                      <Text
                        as="span"
                        display="inline-block"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        cursor="pointer"
                        maxWidth="50%"
                        onClick={handleTooltipClick}
                      >
                        {`${nft?.name} #${nft?.tokenId}`}
                      </Text>
                    </Tooltip>
                    <Text>Soccer player</Text>
                  </Box>
                  <Divider borderColor="black" />
                  <Box display="flex" justifyContent="space-between">
                    Current Bid<Text>{nft?.price} PLS</Text>
                  </Box>
                  <Flex width="100%" justifyContent="flex-end">
                    <Text>≈ ${nft?.priceInDollars} USD</Text>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      </Stack>
    </Center>
  );
};
