import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
import styles from './NFTCard.module.css';
import { Box, Divider, Flex, Img, Text, Tooltip } from '@chakra-ui/react';
import { useMetamask } from '../../useContext/MetamaskContext';
import { useLoadingContext } from '../../useContext/LoaderContext';
import { allowance } from '../../helpers/allowanceNft';
import { ButtonBuyNFT } from '../ButtonBuyNFT/ButtonBuyNFT';
import { Link } from 'react-router-dom';
import { ButtonChangePriceNFT } from '../ButtonChangePriceNFT/ButtonChangePriceNFT';
import { ButtonDelistNFT } from '../ButtonDelistNFT/ButtonDelistNFT';
import { ButtonApproveNFT } from '../ButtonApproveNFT/ButtonApproveNFT';
import { ButtonTransferNFT } from '../ButtonTransferNFT/ButtonTransferNFT';
import { ButtonResellNFT } from '../ButtonResellNFT/ButtonResellNFT';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';

const NFTCard = React.memo(
  ({
    context = 'marketplace',

    nft,
    addressMetamask = '0x7a1f4C7e40fff13ED3DAA15C653bCeb5c0aDc36f',

    isOwnedByCurrentUser,
  }) => {
    // if (!addressMetamask) {
    //   console.log('SE METE HASTA CARD FALSE: ESTE', addressMetamask);
    //   return null;
    // }
    const [isTooltipOpenName, setIsTooltipOpenName] = useState(false);
    const [isTooltipOpenPrice, setIsTooltipOpenPrice] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      const getApprove = async () => {
        setLoading(true);
        const approved = await allowance({ tokenId: nft.tokenId }).finally(
          setLoading(false)
        );
        setIsApproved(approved);
      };

      if (isOwnedByCurrentUser && window.location.pathname === '/myassets') {
        getApprove();
      }
    }, []);
    return (
      <>
        {loading && <LoaderBlock />}
        <Box
          data-testid="nft-card-container"
          width="300px"
          height="500px"
          bgColor="white"
          border="2px solid var(--purple-900, #6032C7)"
          p="10px"
          rounded="20px"
        >
          <Box
            bgColor="#E7EEFF"
            rounded="20px"
            width="100%"
            height="300px"
            overflow="hidden"
          >
            <Link state={{ nft: nft }} to={`/nftdetail/${nft?.tokenId}`}>
              <Img
                width="300px"
                height="300px"
                objectFit="cover"
                src={nft?.image}
              />
            </Link>
          </Box>
          <Box p="20px">
            <Box display="flex" justifyContent="space-between">
              <Tooltip
                isOpen={isTooltipOpenName}
                label={`${nft?.name} #${nft?.tokenId}`}
                placement="top"
                hasArrow
              >
                <Text
                  as="span"
                  margin="0"
                  display="inline-block"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  cursor="pointer"
                  maxWidth="50%"
                  onClick={() => setIsTooltipOpenName(!isTooltipOpenName)}
                  fontFamily="GeneralSans"
                  fontWeight="bold"
                  fontSize="18px"
                >
                  {`${nft?.name} #${nft?.tokenId}`}
                </Text>
              </Tooltip>
              <Text margin="0">Soccer player</Text>
            </Box>
            <Divider borderTopWidth="2px" margin="0" borderColor="black" />
            <Box display="flex" justifyContent="space-between">
              Current Bid
              <Tooltip
                hasArrow
                isOpen={isTooltipOpenPrice}
                label={`${nft?.priceInDollars}USD ${nft?.price}PLS`}
                placement="top"
              >
                <Text
                  as="span"
                  display="inline-block"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  cursor="pointer"
                  margin="0"
                  maxWidth="50%"
                  fontFamily="GeneralSans"
                  fontWeight="bold"
                  fontSize="18px"
                  onClick={() => setIsTooltipOpenPrice(!isTooltipOpenPrice)}
                >
                  {`${nft?.priceInDollars}USD ${nft?.price}PLS`}
                </Text>
              </Tooltip>
            </Box>

            <Box marginTop="15px">
              {context === 'marketplace' && (
                <>
                  {isOwnedByCurrentUser ? (
                    <Flex gap="10px">
                      <Box flex="1">
                        <ButtonDelistNFT flex="1" nft={nft} />
                      </Box>

                      <Box flex="1">
                        <ButtonChangePriceNFT nft={nft} />
                      </Box>
                    </Flex>
                  ) : (
                    <ButtonBuyNFT nft={nft} />
                  )}
                </>
              )}

              {context === 'userAssets' && (
                <>
                  {!isApproved ? (
                    <ButtonApproveNFT nft={nft} setIsApproved={setIsApproved} />
                  ) : (
                    <Flex gap="10px">
                      <ButtonResellNFT nft={nft} />
                      <ButtonTransferNFT nft={nft} />
                    </Flex>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);

export default NFTCard;
