import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../frontend/App';
import { logout } from '../frontend/services/users';
import { showWarning } from '../frontend/components/alerts/notifications';
import Swal from 'sweetalert2';

export default class extends Controller {
  connect() {
    function handleAccountsChanged(accounts) {
      let timerInterval;
      Swal.fire({
        title: 'Your Metamask account has changed',
        html: `Your session will be closed in <b>${timerInterval}</b> milliseconds.`,
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: false, // Evitar que el usuario haga clic fuera de la notificación
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
          logout(); // Realizar logout cuando la notificación se cierre automáticamente
        },
      });
    }

    function loadAfterDOM() {
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      const container = document.getElementById('root');
      const root = ReactDOM.createRoot(container);
      root.render(<App />);
    }

    if (document.readyState === 'complete') {
      // Si el DOM ya está completamente cargado, ejecuta la función directamente
      loadAfterDOM();
    } else {
      // Si no, espera al evento 'DOMContentLoaded' para ejecutar la función
      document.addEventListener('DOMContentLoaded', loadAfterDOM);
    }
  }

  disconnect() {
    window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
    console.log('deactivated');
  }
}
