// Binance Smart Chain
//export const nftaddress = "0xB2eF2448CD1b5BDA9211ccA103be801c1E2E08B4";
//export const nftmarketaddress = "0xcB2788f8D46C1f6f2C22D3A6b455D2E62EFcC69a";

//Pulsechain
export const nftaddress = process.env.NFTADDRESS || '0x0';
export const nftmarketaddress = process.env.NFTMARKETADDRESS || '0x0';
// export const getApiUrl = path => `http://localhost:3000/v1/${path}`;

export const ownerAddress = process.env.OWNER_ADDRESS || '0x0';

// Ganache
// export const nftaddress = '0x5111dE250CBdD5BcAF829034Ac0EA21CA5d3F673';
// export const nftmarketaddress = '0xD0F9bEAcb40D84E176d5b529ef88197C819c03bD';
export const getApiUrl = (path) => `/api/v1/${path}`;
// export const getApiUrl = (path) =>
//   `https://sportex-staging.herokuapp.com/api/v1/${path}`;
