import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import { approve } from '../../helpers/approveNft';
export const ButtonApproveNFT = ({
  colorButton,
  nft,
  color,
  setIsApproved,
}) => {
  const [loading, setLoading] = useState(false);
  const handleApproveNFT = async () => {
    try {
      setLoading(true);
      const res = await approve({ tokenId: nft.tokenId });
      setIsApproved(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <LoaderBlock />}
      <Button
        onClick={handleApproveNFT}
        _hover={{ background: '#0044AA' }}
        bgColor={colorButton ? colorButton : '#003399'}
        color={color ? color : 'white'}
        width="100%"
      >
        APPROVE
      </Button>
    </>
  );
};
