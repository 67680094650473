import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

export const filterNFTs = ({
  items,
  category,
  typeOrder,
  nameToFilter,
  teamName,
  searchMyNFTs,
  address,
  priceRange,
  filterByRange,
}) => {
  // if (!category && !typeOrder && !nameToFilter && !teamName) return items;
  try {
    let data = [...items];
    if (filterByRange) {
      const minPrice = priceRange[0];
      const maxPrice = priceRange[1];

      data = items.filter(
        (item) => item.price >= minPrice && item.price <= maxPrice
      );
    }
    if (searchMyNFTs) {
      data = data.filter((nft) => {
        return nft.seller.toLowerCase() === address.toLowerCase();
      });
    }
    if (teamName) {
      data = data.filter((nft) => {
        return nft.teamName === teamName;
      });
    }

    if (nameToFilter) {
      const fuse = new Fuse(data, {
        keys: ['name'],
        shouldSort: true,
        includeMatches: true,
        threshold: 0.3,
        ignoreLocation: true,
        useExtendedSearch: true,
        minMatchCharLength: 1,
        matchAllTokens: true,
        distance: 100,
        findAllMatches: true,
        isCaseSensitive: false,
      });

      const result = fuse.search(nameToFilter);
      data = result.map((result) => result.item);
    }

    if (category) {
      // const categoryDown = category?.toLowerCase();
      if (typeOrder === 'asc') {
        // alert('ASC');
        data.sort((a, b) => a[category] - b[category]);
      } else if (typeOrder === 'desc') {
        // alert('DESC');
        data.sort((a, b) => b[category] - a[category]);
      }
    }
    return data;
  } catch (error) {
    console.error(error);
  }
};
