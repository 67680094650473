import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NFTS } from '../graphql/nft/graphql-queries';
import { fetchBNBPrice } from '../services/fetchBNBPrice';

const MarketplaceNFTContext = createContext({
  setCurrentPage: () => {},
  getNFTs: () => {},
  currentPage: 0,
  totalNfts: 0,
  totalPagesPagination: 0,
  NFTs: [],
  loading: null,
  error: null,
});

export const useMarketplaceNFTContext = () => useContext(MarketplaceNFTContext);

export const MarketplaceNFTProvider = ({ children }) => {
  const [NFTs, setNFTs] = useState([]);
  const [totalPagesPagination, setTotalPagesPagination] = useState(0);
  const [totalNfts, setTotalNfts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [getNFTs, { data, loading, error }] = useLazyQuery(GET_NFTS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    console.log('ENTRA');
    const fetchBnbPrice = async () => {
      try {
        console.log('====================================');
        console.log(data, 'DATA NFTS');
        console.log('====================================');
        const bnbPrice = await fetchBNBPrice();
        const updatedNfts =
          data?.allNFTs?.nfts.map((nft) => ({
            ...nft,
            priceInDollars: (nft.price * bnbPrice).toFixed(2),
          })) || [];
        console.log('=============TOTALPAGES===============');
        console.log(data?.allNFTs?.nfts);
        console.log('====================================');
        setTotalPagesPagination(data?.allNFTs?.totalPages || 0);
        setTotalNfts(data?.allNFTs?.totalNfts || 0);
        setNFTs(updatedNfts);
      } catch (error) {
        console.error('Error fetching crypto price:', error);
      }
    };

    if (data) {
      fetchBnbPrice();
    }
  }, [data]);

  useEffect(() => {
    getNFTs();
  }, [currentPage]);

  return (
    <MarketplaceNFTContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        totalNfts,
        totalPagesPagination,
        NFTs,
        getNFTs,
        loading,
        error,
      }}
    >
      {children}
    </MarketplaceNFTContext.Provider>
  );
};
