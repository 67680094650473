import { Center, Text } from '@chakra-ui/react';
import React from 'react';

export const Error = ({ message = 'Ocurrio algun error' }) => {
  return (
    <Center>
      <Text color="red" fontSize="40px" fontWeight="bold">
        {message}
      </Text>
    </Center>
  );
};
