import { Box, Card, CardBody, Checkbox, Img, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export const PaymentCard = ({
  isSelected,
  children,
  onSelect,
  nameSelection,
  icon,
  selectedDefault,
  setSelectedDefault,
  title,
}) => {
  const handleSetDefaultSelection = () => {
    setSelectedDefault(nameSelection);
    localStorage.setItem('defaultPaymentSporteX', nameSelection);
  };
  return (
    <Card
      position="relative"
      cursor="pointer"
      bgColor={isSelected ? '#E7EEFF' : ''}
      borderColor={isSelected ? '1px solid #99BCFF' : ''}
      onClick={() => onSelect(nameSelection)}
    >
      <CardBody>
        <Checkbox
          isChecked={isSelected}
          position="absolute"
          right="10px"
          top="10px"
        />
        {children}
        <Box display="flex" alignItems="center" gap="10px">
          <Img src={icon} width="40px" height="40px" />
          <Box>
            <Text margin="0">{title}</Text>
            <Box display="flex" gap="10px">
              {selectedDefault !== nameSelection ? (
                <>
                  <Text margin="0">Set as default</Text>
                  <Text
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSetDefaultSelection();
                    }}
                    margin="0"
                    fontWeight="bold"
                    color="blue.500"
                    cursor="pointer"
                  >
                    Edit
                  </Text>
                </>
              ) : (
                'Is default'
              )}
            </Box>
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};
