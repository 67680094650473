import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import { Link } from 'react-router-dom';

export const ButtonBuyNFT = ({ colorButton, nft, color }) => {
  const [loading, setLoading] = useState(false);
  const [nftSaved, setNftSaved] = useState(false); // Flag to track if NFT is saved

  // const navigate = useNavigate();

  const handleBuyNFT = async () => {
    setLoading(true);
    localStorage.setItem('nft_sportx_to_buy', JSON.stringify(nft));
  };

  return (
    <>
      {loading && <LoaderBlock />}
      <Link to="/checkoutPage">
        <Button
          onClick={handleBuyNFT}
          _hover={{ background: '#0044AA' }}
          bgColor={colorButton ? colorButton : '#003399'}
          color={color ? color : 'white'}
          width="100%"
        >
          BUY IT
        </Button>
      </Link>
    </>
  );
};
