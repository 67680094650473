import React, { useEffect, useState } from 'react';
import styles from './Pagination.module.css';
import { Box } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { GET_NFTS } from '../../graphql/nft/graphql-queries';
import { useMarketplaceNFTContext } from '../../useContext/MarketplaceNFTProvider';

export const Pagination = ({ totalPages, setCurrentPage, currentPage }) => {
  const { getNFTs } = useMarketplaceNFTContext();

  const previous = () => {
    if (currentPage >= 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const handleRefetch = () => {
    console.log('CLICK');
    getNFTs();
  };
  const next = () => {
    console.log('DATOS');
    console.log(totalPages);
    console.log(currentPage);
    console.log('DATOS');
    if (currentPage < totalPages) {
      setCurrentPage((prev) => {
        return prev + 1;
      });
    }
  };

  return (
    <Box>
      <div className={styles.container}>
        <div>
          <button
            disabled={currentPage === 1}
            onClick={previous}
            className={styles.previous}
          >
            {'<'} Previous
          </button>
        </div>
        <div>
          <span className={styles.currentPage}>{currentPage}</span>
        </div>
        <div>
          <button
            disabled={currentPage === totalPages}
            onClick={next}
            className={styles.next}
          >
            Next {'>'}
          </button>
        </div>
      </div>
    </Box>
  );
};
