// React Component
import React from 'react';
import imgBanner from '../../img/nftBanner.webp';
import Nft from '../../img/NFT.webp';
import styles from './banner.module.css';

const Banner = () => {
  return (
    <>
      {/* <div style={{ width: '100%', height: '100vh', position: 'relative' }}> */}
      {/* </div> */}
      <div className={styles.container}>
        <img className={styles.imageNFT} src={Nft} alt="" />

        <div className={styles.containerSections}>
          <section className={styles.sectionOne}>
            <h1>Discover, and collect Digital Player NFTs</h1>
            <p>
              NFT FIFA Is a card strategy game by turns, build your deck,
              customize your team and play on eve and pop mode to rise to the
              top
            </p>
            <button style={{ fontWeight: 'bold' }}>Play in SporteX</button>
          </section>
          <section className={styles.sectionTwo}>
            <div className={styles.contentSectionTwo}>
              <div className={styles.containerSTATS}>
                <div>
                  <h1>98</h1>
                  <p>Players</p>
                </div>
                <div>
                  <h1>98</h1>
                  <p>Players</p>
                </div>
                <div>
                  <h1>98</h1>
                  <p>Players</p>
                </div>
              </div>
              <img src={imgBanner} alt="nft image" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Banner;
