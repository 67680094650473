import { showError, showSuccess } from '../components/alerts/notifications';
import Market from '../../hardhat/artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import { nftmarketaddress } from '../config';
import Web3 from 'web3';
export async function resellToken({ nft, newPrice }) {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.requestAccounts();
    const contract = new web3.eth.Contract(Market.abi, nftmarketaddress);
    const priceFormatted = web3.utils.toWei(newPrice, 'ether');

    const transaction = await contract.methods
      .resellToken(nft.tokenId, priceFormatted)
      .send({ from: accounts[0] });

    window.location.replace('/');
  } catch (error) {
    console.error('Error function resellToken:', error);
    throw new Error('Error resell NFT');
  }
}
