import { Button } from "@chakra-ui/react";
import React from "react";

export function ButtonFilter({ width, handleFilter }) {
  return (
    <Button
      width={width}
      onClick={handleFilter}
      bgColor="#003399"
      _hover={{ bgColor: "#0240BB" }}
      color="white"
    >
      FILTER
    </Button>
  );
}
