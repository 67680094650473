import Market from '../../hardhat/artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import {
  showError,
  showErrorWithButton,
  showSuccess,
} from '../components/alerts/notifications';
import { nftmarketaddress } from '../config';
import { ethers } from 'ethers';
export default async function delistNft({ nft }) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer);
    const transaction = await contract.delistNFT(nft.tokenId);
    await transaction.wait();

    showSuccess({
      title: 'NFT Delisted',
      message: 'Your NFT has been delisted successfully.',
    });
  } catch (error) {
    console.log(error);
    showErrorWithButton({
      title: 'Error',
      message: 'Error when delisting NFT, try again later',
    });
    throw new Error('Error delist NFT in function');
  }
}
