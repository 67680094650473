// Entry point for the build script in your package.json
// import '@hotwired/turbo-rails';
import './controllers/index';
import {
  showError,
  showSuccess,
} from './frontend/components/alerts/notifications';
document.addEventListener('DOMContentLoaded', function () {
  console.log('DOM loaded');

  const flashMessages = document.querySelectorAll('.flash-message-content');

  flashMessages.forEach((flashMessage) => {
    const type = flashMessage.getAttribute('data-type');

    if (type === 'notice') {
      showSuccess({ title: '', message: flashMessage.innerText });
    } else {
      showError({ title: '', message: flashMessage.innerText });
    }
  });
});
