import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CustomModal } from '../customModal/CustomModal';
import { useForm } from 'react-hook-form';
import { transferToken } from '../../helpers/transferToken';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import {
  showErrorWithButton,
  showSuccessWithButton,
} from '../alerts/notifications';
import { useNavigate } from 'react-router-dom';

export const ButtonTransferNFT = ({ colorButton, nft, color }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
  });
  const handleTransferNFT = async (data) => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const { transferAddress } = getValues();
    setLoading(true);
    try {
      await transferToken({ nft, transferAddress });
      showSuccessWithButton({
        title: 'Success',
        message: 'The NFT has been transferred successfully',
      });
      navigate('/');
    } catch (error) {
      console.error('Error component ButtonTransferNFT', error);
      showErrorWithButton({
        title: 'Error',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderBlock />}
      <Button
        onClick={onOpen}
        _hover={{ background: '#0044AA' }}
        bgColor={colorButton ? colorButton : '#003399'}
        color={color ? color : 'white'}
        width="100%"
      >
        Transfer
      </Button>

      <CustomModal
        isOpen={isOpen}
        modalTitle={'Transfer NFT'}
        onClose={onClose}
        onConfirm={handleTransferNFT}
      >
        <FormControl isInvalid={errors?.transferAddress}>
          <FormLabel>Address to transfer</FormLabel>
          <Input
            placeholder="0x000"
            {...register('transferAddress', {
              required: 'This field is required',
            })}
          />
          {errors.transferAddress && (
            <FormErrorMessage>
              {errors?.transferAddress?.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </CustomModal>
    </>
  );
};
