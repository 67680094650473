import React from 'react';
import Banner from '../../layouts/banner/Banner';

import NFTCollectionMarketplace from '../../components/NFTCollectionMarketplace/NFTCollectionMarketplace';
import { Wave } from '../../components/Wave/Wave';

const Welcome = () => {
  return (
    <div>
      <Banner />
      <Wave />
      {/* <div className={styles.contentIndex}> */}
      <NFTCollectionMarketplace />
      {/* </div> */}
    </div>
  );
};
export default Welcome;
