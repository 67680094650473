import { Controller } from '@hotwired/stimulus';
import { showModal } from './js/modal';
import {
  metamaskIsInstalled,
  currentChainIsValid,
  requestAccounts,
  personalSign,
  getUuidByAccount,
} from './js/ethUtils';
import { showWarningWithButton } from '../frontend/components/alerts/notifications';
// Connects to data-controller="sessions"
export default class extends Controller {
  connect() {
    const buttonEthConnect = document.querySelector('.ButtonEthConnect');
    const formInputEthMessage = document.querySelector('input.eth_message');
    const formInputEthAddress = document.querySelector('input.eth_address');
    const formInputEthSignature = document.querySelector('input.eth_signature');
    const formNewSession = document.querySelector('form');
    const installMetamaskLink = document.querySelector('.linkMetamask');
    formInputEthMessage.hidden = true;
    formInputEthAddress.hidden = true;
    installMetamaskLink.hidden = true;
    formInputEthSignature.hidden = true;

    connectToEthereum();
    async function connectToEthereum() {
      if (await metamaskIsInstalled()) {
        buttonEthConnect.addEventListener('click', async () => {
          // buttonEthConnect.disabled = true;

          await currentChainIsValid();
          let accounts;
          try {
            accounts = await requestAccounts();
            // hacer algo con accounts
          } catch (error) {
            showWarningWithButton({
              title: 'Error',
              message:
                'You already have a request in progress, check your MetaMask inbox',
            });
            throw error; // aquí se propaga la excepción
          }
          z;
          if (!nonce) {
            showWarningWithButton({
              title: 'User no exist',
              message: 'Your user is not registered please register',
            });
            return;
          }

          const customTitle = 'SportXSync';
          const requestTime = new Date().getTime();
          const message = customTitle + ',' + requestTime + ',' + nonce;

          let signature;
          try {
            signature = await personalSign(etherbase, message);
          } catch (error) {
            console.error(error);
            showWarningWithButton({
              title: 'Error',
              message:
                'An error occurred while obtaining your personal signature in metamask',
            });
          }
          // if (!signature || !message) {
          //   notification.showWarningWithButton()
          //   return
          // }
          formInputEthMessage.value = message;
          formInputEthAddress.value = etherbase;
          formInputEthSignature.value = signature;
          formNewSession.submit();
        });
      } else {
        showModal(true);
        installMetamaskLink.hidden = false;
        buttonEthConnect.innerHTML = 'No Ethereum Context Available';
        buttonEthConnect.disabled = true;
      }
    }
    function disableButtonsChainInvalid() {
      // Disable the button and change its text
      buttonEthConnect.innerHTML = 'Change chain valid';
      buttonEthConnect.disabled = true;
    }
  }
}
