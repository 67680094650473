import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalFooter,
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { resellToken } from '../../helpers/resellToken';
import { useLoadingContext } from '../../useContext/LoaderContext';
import { useForm } from 'react-hook-form';
import { CustomModal } from '../customModal/CustomModal';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import { showErrorWithButton, showSuccess } from '../alerts/notifications';

export const ButtonResellNFT = React.memo(
  ({ colorButton, nft, color, askingPrice }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const {
      register,
      trigger,
      handleSubmit,
      getValues,
      formState: { errors },
    } = useForm({ mode: 'onChange' });

    const handleResellToken = async (data) => {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      setLoading(true);
      try {
        const { newPrice } = getValues();
        await resellToken({ nft, askingPrice, newPrice: newPrice });
        onClose();
        showSuccess({
          title: 'Success',
          message: 'The NFT will publish for sale in a few minutes',
        });
      } catch (error) {
        showErrorWithButton({
          title: 'Error',
          message: 'Error when reselling NFT, try again later',
        });
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        {loading && <LoaderBlock />}
        <Button
          onClick={() => onOpen()}
          _hover={{ background: '#0044AA' }}
          bgColor={colorButton ? colorButton : '#003399'}
          color={color ? color : 'white'}
          width="100%"
        >
          Resell
        </Button>

        <CustomModal
          isOpen={isOpen}
          modalTitle={'Resell NFT'}
          onClose={onClose}
          onConfirm={handleResellToken}
        >
          <FormControl isInvalid={errors.newPrice}>
            <FormLabel>New price</FormLabel>
            <Input
              type="number"
              step="0.01"
              {...register('newPrice', {
                required: 'This field is required',
                min: {
                  value: 0.001,
                  message: 'Price must be greater than 0',
                },
              })}
            />
            {errors.newPrice && (
              <FormErrorMessage>{errors.newPrice.message}</FormErrorMessage>
            )}
          </FormControl>
        </CustomModal>
      </>
    );
  }
);
