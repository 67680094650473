import Market from '../../hardhat/artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import { nftmarketaddress } from '../config';
import {
  showErrorWithButton,
  showSuccessWithButton,
} from '../components/alerts/notifications';
import Web3 from 'web3';
export async function paymentWithMetamask({ nft, addressMetamask }) {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.requestAccounts();
    const contract = new web3.eth.Contract(Market.abi, nftmarketaddress);

    const price = web3.utils.toWei(nft?.price.toString(), 'ether');

    const transaction = await contract.methods.purchaseItem(nft.tokenId).send({
      value: price,
      from: accounts[0],
    });

    const { details } = await savePayment({
      amountPaymentInUSD: nft?.priceInDollars,
      nftTokenId: nft?.tokenId,
      userAddressMetamask: addressMetamask,
    });
    showSuccessWithButton({
      title: 'Payment successful.',
      message:
        'Your payment has been processed successfully and will be sent to your account in the next few hours.',
    });
    return details;
  } catch (error) {
    console.error(error.code === -32603);
    if (error.code === -32603) {
      showErrorWithButton({
        title: 'Payment Error',
        message:
          "You don't have enough gas for this purchase, please verify you selected the correct purchase address",
      });
    } else {
      showErrorWithButton({
        title: 'Payment Error',
        message:
          'An error occurred while processing the payment with Metamask.',
      });
    }
    throw error;
  }
}
export const paymentWithCard = async ({
  stripe,
  elements,
  nft,
  amountInCents,
  addressMetamask,
}) => {
  try {
    const { clientSecret } = await createPaymentIntent({
      addressMetamask,
      amountInCents,
      nftId: nft?.id,
    });
    if (!clientSecret) {
      showErrorWithButton({
        title: 'Error',
        message: 'Something went wrong with create payment intent',
      });
      return;
    }
    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret,
      elements,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
      redirect: 'if_required',
    });
    if (error) {
      showErrorWithButton({
        title: 'Error',
        message: 'Something went wrong with confirm payment',
      });
      return;
    }

    const { details } = await savePayment({
      amountPaymentInUSD: nft?.priceInDollars,
      nftTokenId: nft?.tokenId,
      paymentId: paymentIntent.id,
      userAddressMetamask: addressMetamask,
    });

    if (details) {
      showSuccessWithButton({
        title: 'Payment successful.',
        message:
          'Your payment has been processed successfully and will be sent to your account in the next few hours.',
      });
      return details;
    }
  } catch (error) {
    console.error(error, 'ERROR');
    showErrorWithButton({
      title: 'Error',
      message: 'Something went wrong with payment',
    });
    throw new Error('Error in paymentCard function');
  }
};

async function createPaymentIntent({ amountInCents, nftId, addressMetamask }) {
  try {
    const response = await fetch('/create_payment_intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount: amountInCents,
        nft_id: nftId,
        addressMetamask,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      return {
        clientSecret: data.payment_intent_info.client_secret,
      };
    } else {
      // La respuesta no fue satisfactoria (código de estado HTTP fuera del rango 200-299)
      console.error(
        'Error en la respuesta HTTP:',
        response.status,
        response.statusText
      );
      showErrorWithButton({
        title: 'Error',
        message: 'Ocurred an error in payment Stripe',
      });
    }
  } catch (error) {
    showErrorWithButton({
      title: 'Error',
      message: 'Ocurred an error in payment Stripe',
    });
    throw error;
  }
}

async function savePayment({
  nftTokenId,
  paymentId,
  userAddressMetamask,
  amountPaymentInUSD,
}) {
  try {
    const requestBody = {};

    if (paymentId) {
      requestBody.paymentIntentId = paymentId;
    }
    requestBody.nftTokenId = nftTokenId;
    requestBody.userAddressMetamask = userAddressMetamask;
    requestBody.amountPaymentInUSD = amountPaymentInUSD;
    const response = await fetch('/save_payment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();
      return { details: data.payment_details };
    } else {
      console.error(
        'Error en la respuesta HTTP:',
        response.status,
        response.statusText
      );
      showErrorWithButton({
        title: 'Error',
        message: 'Ocurred an error in save payment',
      });
    }
  } catch (error) {
    showErrorWithButton({
      title: 'Error',
      message: 'Ocurred an error in save payment',
    });
    throw error;
  }
}
