import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLoadingContext } from '../../useContext/LoaderContext';
import delistNft from '../../helpers/delistNft';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import { useMarkAsSold } from '../../graphql/nft/custom-hooks';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { MARK_AS_SOLD } from '../../graphql/nft/graphql-mutations';
import {
  showErrorWithButton,
  showSuccessWithButton,
} from '../alerts/notifications';
import { GET_NFTS } from '../../graphql/nft/graphql-queries';
import { useMarketplaceNFTContext } from '../../useContext/MarketplaceNFTProvider';

export const ButtonDelistNFT = ({ color, colorButton, nft }) => {
  const client = useApolloClient();
  const [markAsSold] = useMutation(MARK_AS_SOLD);
  const [loading, setLoading] = useState(false);
  const { getNFTs } = useMarketplaceNFTContext();
  const handleChangePrice = async () => {
    setLoading(true);

    try {
      await delistNft({ nft, markAsSold });
      await markAsSold({
        variables: {
          tokenId: nft?.tokenId,
        },
      });
      getNFTs();
      await client.cache.evict({ id: `Nft:${nft?.id}` });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // async function error(){
  //   await setTimeout

  // }
  return (
    <>
      {loading && <LoaderBlock />}
      <Button
        onClick={handleChangePrice}
        _hover={{ background: '#0044AA' }}
        bgColor={colorButton ? colorButton : '#003399'}
        color={color ? color : 'white'}
        width="100%"
      >
        DELIST
      </Button>
    </>
  );
};
