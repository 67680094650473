import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import styles from './NftInfo.module.css';
import { GET_NFT } from '../../graphql/nft/graphql-queries';
import { ButtonBuyNFT } from '../../components/ButtonBuyNFT/ButtonBuyNFT';
import { useMetamask } from '../../useContext/MetamaskContext';
import { fetchBNBPrice } from '../../services/fetchBNBPrice';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Img,
  List,
  ListIcon,
  ListItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';

const SHADOWITEMS = '0px 8px 84px 0px rgba(0, 0, 0, 0.20)';
const GRADIANTTABLECREATE =
  'linear-gradient(94deg, rgba(96, 50, 199, 0.60) 2.84%, rgba(173, 196, 227, 0.48) 99.18%)';

const GRADIANTITEMS =
  'linear-gradient(169deg, rgba(58, 129, 191, 0.08) 1.85%, rgba(65, 48, 90, 0.08) 98.72%)';
const NftInfo = () => {
  const { state } = useLocation();

  if (!state?.nft) {
    return <Navigate to={'/'} />;
  }
  const [nft, setNft] = useState(state?.nft ? state.nft : null);
  const [cryptoPrice, setCryptoPrice] = useState(null);
  const { addressMetamask } = useMetamask();

  if (!nft) {
    return <p className={styles.loadingText}>Not found...</p>;
  }

  return (
    <>
      <Grid
        gap={4}
        templateColumns={{ base: '1fr', lg: 'repeat(2,1fr)' }}
        overflow="hidden"
        p="20px"
      >
        <Box
          bg="linear-gradient(180deg, rgba(0, 51, 153, 0.50) 0%, rgba(51, 119, 254, 0.50) 100%)"
          // maxHeight="650px"
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box style={{ maxWidth: '100%', maxHeight: '100%' }}>
            <Box style={{ maxWidth: '100%', maxHeight: '100%' }}>
              <Img src={nft?.image} w="350px" h="350px" objectFit="cover" />
            </Box>
            {nft?.owner?.toLowerCase() === addressMetamask ||
            nft?.seller?.toLowerCase() === addressMetamask ? (
              ''
            ) : (
              <Box>
                <ButtonBuyNFT nft={nft} color="black" colorButton="#86FC73" />
              </Box>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          maxHeight="650px"
          overflow="hidden"
        >
          <Box bg={GRADIANTITEMS} flex="1" padding="20px" color="white">
            <VStack spacing={2} alignItems="flex-start">
              <Box display="flex" alignItems="center">
                <Text fontSize="lg" fontWeight="bold" mr="2">
                  Name:
                </Text>
                <Text>{nft?.name}</Text>
              </Box>
              <Text fontSize="lg" fontWeight="bold">
                Sale
              </Text>
              <List spacing={3}>
                <ListItem>Price in Dollars: {nft?.priceInDollars}</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Price in ETC: {nft?.price}</ListItem>
              </List>
            </VStack>
          </Box>
          <Box flex="1" color="white" padding="20px" bg={GRADIANTITEMS}>
            <VStack spacing={2} alignItems="flex-start">
              <Text fontSize="lg" fontWeight="bold" mr="2">
                NFT Information
              </Text>
              <List spacing={3}>
                <ListItem>Seller: {nft?.seller}</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Creator: {nft?.owner}</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Token ID: {nft?.tokenId}</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Total copies of this NFT: 1</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Amount remaining: 1</ListItem>
              </List>
              <List spacing={3}>
                <ListItem>Is Transferable: Yes</ListItem>
              </List>
            </VStack>
          </Box>
        </Box>
        <Box p="4" shadow={SHADOWITEMS} bg={GRADIANTITEMS} color="white">
          <Table variant="unstyled">
            <Thead>
              <Tr>
                <Th>Characteristics</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr bg={GRADIANTTABLECREATE}>
                <Td borderStartRadius={'10px'}>Attack</Td>
                <Td borderEndRadius={'10px'}>{nft?.attack}</Td>
              </Tr>
              <Tr>
                <Td>Defense</Td>
                <Td>{nft?.defense}</Td>
              </Tr>
              <Tr bg={GRADIANTTABLECREATE}>
                <Td borderStartRadius={'10px'}>Resistance</Td>
                <Td borderEndRadius={'10px'}>{nft?.resistance}</Td>
              </Tr>
              <Tr>
                <Td>Team Name</Td>
                <Td>{nft?.teamName}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Grid>
    </>
  );
};

export default NftInfo;
