import Market from '../../hardhat/artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import { nftmarketaddress } from '../config';
import { ethers } from 'ethers';

export default async function changePriceProxy({ nft, price }) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer);

    const priceFormatted = ethers.utils.parseEther(price);

    await contract.changePrice(nft.tokenId, priceFormatted);
    // await transaction.wait();
  } catch (error) {
    console.error('Error function changePriceProxy:', error);
    throw new Error('Error function changePriceProxy');
  }
}
