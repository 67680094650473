import React, { useContext, useEffect, useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  split,
} from '@apollo/client';
import { getApiUrl } from './config';
import { MetamaskProvider } from './useContext/MetamaskContext';
import Index from './routes/routes';
import './app.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingProvider } from './useContext/LoaderContext';
import { ChakraProvider } from '@chakra-ui/react';

import { extendTheme } from '@chakra-ui/react';
import { PointsUserProvider } from './useContext/PointsUserContext';
import { createUploadLink } from 'apollo-upload-client';
import { HttpLink } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { MarketplaceNFTProvider } from './useContext/MarketplaceNFTProvider';

export default function App() {
  const tokenElement = document.querySelector('[name=csrf-token]');

  const uploadLink = createUploadLink({
    uri: getApiUrl('graphql'), // La URL GraphQL para la carga de archivos
    headers: {
      'X-CSRF-Token': tokenElement ? tokenElement.content : '',
    },
  });

  const httpLink = createHttpLink({
    uri: getApiUrl('graphql'), // La URL GraphQL para solicitudes normales
    headers: {
      'X-CSRF-Token': tokenElement ? tokenElement.content : '',
    },
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([uploadLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const customTheme = extendTheme({
    styles: {
      global: {
        body: {
          backgroundColor: '#001b4b',
          // maxWidth: '1440px',
          minWidth: '320px',
          height: '100vh',
          // width: '100vw',
          // maWidth: '920px',
          margin: '0 auto',
          overflowX: 'hidden', // Ocultar la barra de desplazamiento horizontal
        },
        button: {
          fontFamily: 'Roboto, sans-serif',
          color: '#000000',
        },
      },
    },
  });

  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <PointsUserProvider>
          <ChakraProvider theme={customTheme}>
            <Elements stripe={stripePromise}>
              <MarketplaceNFTProvider>
                <MetamaskProvider>
                  <LoadingProvider>
                    <Index />
                  </LoadingProvider>
                </MetamaskProvider>
              </MarketplaceNFTProvider>
            </Elements>
          </ChakraProvider>
        </PointsUserProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}
