import { nftaddress, nftmarketaddress } from '../config';
import NFT from '../../hardhat/artifacts/contracts/NFT.sol/NFT.json';
import {
  showErrorWithButton,
  showSuccess,
} from '../components/alerts/notifications';
import { allowance } from './allowanceNft';
import { ethers } from 'ethers';

export async function approve({ setnftIsApproval, tokenId }) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const nftContract = new ethers.Contract(nftaddress, NFT.abi, signer);

    const approveTx = await nftContract.approve(nftmarketaddress, tokenId);
    await approveTx.wait();

    const requestAallowance = await allowance({
      tokenId,
    });
    showSuccess({
      title: 'NFT Approved',
      message: 'Your NFT has been approved successfully.',
    });
    return requestAallowance;
  } catch (error) {
    showErrorWithButton({
      title: 'Error',
      message: 'Error when approving NFT, try again later',
    });
    throw new Error('Error approving NFT in function approve');
  }
}
