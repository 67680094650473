import React from 'react';
import { Flex, Grid, SimpleGrid, Text } from '@chakra-ui/react';
import NFTCard from '../NFTCard/NFTCard';
import { useMetamask } from '../../useContext/MetamaskContext';

const NFTList = React.memo(({ nfts, context }) => {
  const { addressMetamask } = useMetamask();

  if (!nfts || nfts.length === 0)
    return (
      <Text
        textAlign="center"
        fontSize={{ base: '20px', md: '50px' }}
        fontWeight="bold"
      >
        NFTs not found
      </Text>
    );

  return (
    <Grid
      data-testid="nft-list"
      marginTop={20}
      templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      gap={4}
      w="100%"
    >
      {nfts?.map((nft, index) => (
        <Flex
          data-testid={`item-card-${index}`}
          key={nft.tokenId}
          justifyContent="center"
          alignItems="center"
        >
          <NFTCard
            name={nft.name}
            isOwnedByCurrentUser={
              nft.owner.toLowerCase() === addressMetamask?.toLowerCase() ||
              nft.seller.toLowerCase() === addressMetamask?.toLowerCase()
            }
            token_id={nft.tokenId}
            context={context}
            nft={nft}
          />
        </Flex>
      ))}
    </Grid>
  );
});

export default NFTList;
