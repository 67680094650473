import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import NavbarComponent from '../layouts/navbar/NavbarComponent';
import CreateItem from '../views/createItem/CreateItem';
import Welcome from '../views/Welcome/Welcome';
import MyAssets from '../views/myAssets/MyAssets';
import Profile from '../views/profile/Profile';
import NftInfo from '../views/nftInfo/NftInfo';
import Footer from '../layouts/Footer/Footer';
import { useMetamask } from '../useContext/MetamaskContext';
import { useLoadingContext } from '../useContext/LoaderContext';
import { Checkout } from '../views/Checkout/Checkout';
import { Box } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute';
import { local } from 'web3modal';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Esto lleva la página al principio en cada cambio de ruta
  }, [pathname]);

  return null;
}

export default function App() {
  const { isAllowed } = useMetamask();
  const { transactionIsLoading } = useLoadingContext();
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  const isAllowedCheckout = () => {
    return !!localStorage.getItem('nft_sportx_to_buy');
  };
  return (
    <div>
      {/* {transactionIsLoading ? <LoaderBlock /> : ''} */}

      <BrowserRouter>
        <NavbarComponent />
        <Box padding="80px 0 20px">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route index element={<Welcome />} />
            <Route
              path="/checkoutPage"
              element={
                  <Elements
                    options={{ mode: 'payment', amount: 1099, currency: 'usd' }}
                    stripe={stripePromise}
                  >
                    <Checkout />
                  </Elements>
              }
            />

            <Route
              path="/createitem"
              element={
                <PrivateRoute isAllowed={isAllowed} redirectTo={'/'}>
                  <CreateItem />
                </PrivateRoute>
              }
            />
            <Route path="myassets" element={<MyAssets />} />
            <Route path="nftdetail/:tokenId" element={<NftInfo />} />
            <Route path="profile" element={<Profile />} p />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Box>
        <Footer />
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}
