import React, { useEffect, useRef, useState } from 'react';
import { Wave } from '../../components/Wave/Wave';
import { getUser } from '../../services/users';
import PencelIcon from '../../img/pencelIcon.webp';
import { useMetamask } from '../../useContext/MetamaskContext';
import IconPencel from '../../img/pencelIcon.webp';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Img,
  Input,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  showError,
  showSuccess,
  showWarning,
} from '../../components/alerts/notifications';

export const UPDATE_AVATAR = gql`
  mutation updateAvatar($avatar: Upload!) {
    updateAvatar(avatar: $avatar) {
      username
      avatarUrl
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($username: String, $email: String) {
    updateUser(username: $username, email: $email) {
      username
      email
    }
  }
`;
export const GET_USER = gql`
  query getUser {
    currentUser {
      email
      username
      avatarUrl
    }
  }
`;
export default function Profile() {
  // const client = useApolloClient();
  const [updateUserAvatar] = useMutation(UPDATE_AVATAR);
  const [updateUser, { error: errorUpdateUser }] = useMutation(UPDATE_USER);
  const { data: dataUser } = useQuery(GET_USER);
  const { addressMetamask } = useMetamask();
  const [user, setUser] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const { register, getValues, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setUser({
      ...user,
      username: dataUser?.currentUser?.username,
      email: dataUser?.currentUser?.email,
      avatar_url: dataUser?.currentUser?.avatarUrl,
    });
  }, [dataUser]);

  useEffect(() => {
    setValue('username', user?.username || '');
    setValue('email', user?.email || '');
  }, [user]);

  const onSubmit = handleSubmit(async () => {
    const { username, email } = getValues();

    await updateUser({
      variables: {
        username,
        email,
      },
      onError: (error) => {
        const errorJson = JSON.parse(JSON.stringify(error));
        console.error(errorJson);
        showError({
          title: 'Error',
          message: errorJson.message || 'Error updating user, try again later',
        });
      },
      onCompleted: (data) => {
        showSuccess({
          title: 'Success',
          message: 'User updated successfully',
        });
        setUser({
          ...user,
          username: data.updateUser?.username,
          email: data.updateUser?.email,
        });
      },
    });
  });

  const handleAvatarUpdate = async (file) => {
    if (!file) {
      console.error('File is empty or undefined.');
      return;
    }
    console.log(file, 'FILE');
    if (file.size > 1024 * 1024) {
      showWarning({
        title: 'File too big',
        message: 'The file is too big, please select a smaller one',
      });
      return;
    }

    await updateUserAvatar({
      variables: {
        avatar: file,
      },
      onError: (error) => {
        const errorJson = JSON.parse(JSON.stringify(error));
        console.error(errorJson);
        showError({
          title: 'Error',
          message:
            errorJson.message || 'Error updating avatar, try again later',
        });
      },
      onCompleted: (data) => {
        showSuccess({
          title: 'Success',
          message: 'Avatar updated successfully',
        });
        setUser({
          ...user,
          avatar_url: data.updateAvatar?.avatarUrl,
        });
      },
    });
  };

  if (!user) {
    return <h1>Cargando....</h1>;
  }
  return (
    <Box w="100%" height="100vh" mt="100px" p="30px" bgColor="white">
      <Box
        w={{ base: '100%', md: '500px' }}
        // fontSize={{ base: '10px', md: '15px' }}
      >
        <Heading>Profile</Heading>
        <Box fontSize="1.4em" display="flex" justifyContent="space-between">
          <Text m="0">Profile information</Text>
          <Text
            display={'flex'}
            gap="5px"
            m="0"
            onClick={() => setIsEditable(true)}
          >
            Edit
            <Img w="20px" h="20px" src={IconPencel} />
          </Text>
        </Box>
        <form onSubmit={onSubmit} action="">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="30px"
            // fontSize="1em"
          >
            <Img
              src={user?.avatar_url}
              w="200px"
              h="200px"
              rounded="100%"
              bgColor="#DC3E3E"
            />
            <label
              style={{
                // fontSize: '1.2em',
                lineHeight: '20px',
                color: '#003399',
                borderBottom: '2px solid #003399',
              }}
              htmlFor="inputImage"
            >
              Edit display image
            </label>
            <Input
              onChange={(e) => handleAvatarUpdate(e.target.files[0])}
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple="false"
              id="inputImage"
              display="none"
            />
            <FormControl display="flex">
              <FormLabel>Username</FormLabel>
              <Input
                {...register('username', {
                  required: true,
                })}
                _disabled={{ color: 'black' }}
                isDisabled={!isEditable}
                // defaultValue={user?.username}
              />
            </FormControl>
            <Divider m={'0'} />
            <FormControl display="flex">
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email', {
                  required: true,
                })}
                _disabled={{ color: 'black' }}
                isDisabled={!isEditable}
                // defaultValue={user?.email}
              />
            </FormControl>
          </Box>
          {isEditable && (
            <Box
              mt="30px"
              w="100%"
              display="flex"
              justifyContent="end"
              gap="20px"
            >
              <Button onClick={() => setIsEditable(false)}>Cancel</Button>
              <Button type="submit">Save changes</Button>
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
}
