import React from 'react';

import NFTCollectionUser from '../../components/NFTCollectionUser/NFTCollectionUser';
import { Box } from '@chakra-ui/react';
export default function MyAssets() {
  return (
    <Box minH="800px" p="20px" bgColor="white" position="relative">
      {/* <Wave /> */}
      <div>
        <h1>My assets</h1>

        <NFTCollectionUser />
      </div>
    </Box>
  );
}
