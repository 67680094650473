import { gql } from '@apollo/client';

export const GET_NFTS = gql`
  query allNFTs {
    allNFTs {
      nfts {
        id
        name
        price
        defense
        attack
        status
        teamName
        seller
        owner
        image
        description
        tokenId
        strength
        resistance
      }
      totalNfts
    }
  }
`;

export const GET_NFT = gql`
  query getNft($tokenId: Int!) {
    getNFT(tokenId: $tokenId) {
      id
      name
      price
      defense
      attack
      image
      seller
      owner
      teamName
      description
      tokenId
      strength
    }
  }
`;
