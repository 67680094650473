import { Button } from "@chakra-ui/react";
import React from "react";
export function ModalPriceRange({ onOpenModalPrice }) {
  return (
    <>
      <Button color="#6032C7" w="100%" onClick={onOpenModalPrice}>
        Price
      </Button>
    </>
  );
}
