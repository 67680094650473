import { showError, showSuccess } from '../components/alerts/notifications';
import Market from '../../hardhat/artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import Web3 from 'web3';

import { nftmarketaddress } from '../config';
export async function transferToken({ nft, transferAddress }) {
  try {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.requestAccounts();
    const contract = new web3.eth.Contract(Market.abi, nftmarketaddress);

    const transaction = await contract.methods
      .transferTo(transferAddress, nft.tokenId)
      .send({ from: accounts[0] });

    // await transaction.wait();

    window.location.replace('/');
  } catch (error) {
    console.error('Error function transferToken:', error);
    throw new Error('Error transfering NFT');
  }
}
