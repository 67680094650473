import React, { useState, useEffect } from 'react';
import wavePc from '../../img/waves/wavePc.webp';
import wavePad from '../../img/waves/wave_ipad.webp';
import waveMovil from '../../img/waves/waveMobile.webp';
import styles from './Wave.module.css';
import { Box, Img } from '@chakra-ui/react';
export const Wave = () => {
  const [wave, setWave] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1000) {
        setWave(wavePc);
      } else if (window.innerWidth >= 800) {
        setWave(wavePad);
      } else {
        setWave(waveMovil);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box maxH="150px" mb="-10px" width="100%">
      <Img width="100%" height="100%" src={wave} alt="wave" />
    </Box>
  );
};
