import React, { createContext, useContext, useState } from 'react';

const PointsUserContext = createContext({
  userPoints: 0,
});

export const usePointsUserContext = () => {
  return useContext(PointsUserContext);
};

export const PointsUserProvider = ({ children }) => {
  const [userPoints, setUserPoints] = useState(200);

  const contextValues = {
    userPoints,
  };
  return (
    <PointsUserContext.Provider value={contextValues}>
      {children}
    </PointsUserContext.Provider>
  );
};
