import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CustomModal } from '../customModal/CustomModal';
import changePriceProxy from '../../helpers/changePrice';
import { useForm } from 'react-hook-form';
import { showErrorWithButton, showSuccess } from '../alerts/notifications';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';

export const ButtonChangePriceNFT = ({ color, colorButton, nft }) => {
  const {
    getValues,
    trigger,
    formState: { errors },
    register,
  } = useForm({ mode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleChangePrice = async () => {
    console.error(errors, 'ERRORS');
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const { price } = getValues();
    setLoading(true);
    try {
      await changePriceProxy({ nft, price });
      showSuccess({
        title: 'success',
        message: 'The NFT price has been changed correctly',
      });
    } catch (error) {
      showErrorWithButton({
        title: 'Error',
        message: 'Error when changing price, trypagain later',
      });
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderBlock />}
      <Button
        onClick={onOpen}
        _hover={{ background: '#0044AA' }}
        bgColor={colorButton ? colorButton : '#003399'}
        color={color ? color : 'white'}
        //   width="auto"
      >
        CHANGE PRICE
      </Button>

      <CustomModal
        isOpen={isOpen}
        modalTitle={'Change price'}
        onClose={onClose}
        onConfirm={handleChangePrice}
      >
        <FormControl isInvalid={errors.price}>
          <FormLabel>Price</FormLabel>
          <Input
            {...register('price', {
              required: 'New price is required',
            })}
          />
          {errors?.price && (
            <FormErrorMessage>{errors.price.message}</FormErrorMessage>
          )}{' '}
        </FormControl>
      </CustomModal>
    </>
  );
};
