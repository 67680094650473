import React, { useEffect, useState } from 'react';
import NFTList from '../ListNFT/NFTList';
import loadNFTs from '../../helpers/loadNfts';
import { LoaderBlock } from '../LoaderBlock/LoaderBlock';
import { fetchBNBPrice } from '../../services/fetchBNBPrice';
import { Box, Text } from '@chakra-ui/react';
import { NFTFilter } from '../NFTFilter/NFTFilter';
import { Pagination } from '../Pagination/Pagination';

const NFTCollectionUser = () => {
  const [loading, setLoading] = useState(false);
  const [itemsFiltered, setItemsFiltered] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 10;

  function handleFilter(itemsFiltered) {
    setItemsFiltered(itemsFiltered);
    setCurrentPage(1); // Reset current page when applying a filter
  }

  useEffect(() => {
    setTotalPages(Math.ceil(itemsFiltered?.length / perPage));
  }, [itemsFiltered, perPage]);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        setLoading(true);
        const nfts = await loadNFTs();
        const bnbPrice = await fetchBNBPrice();
        if (!nfts) {
          return;
        }
        const nftsWithDollar = nfts.map((nft) => ({
          ...nft,
          priceInDollars: (nft.price * bnbPrice).toFixed(2),
        }));

        setItemsFiltered(nftsWithDollar);
        setNfts(nftsWithDollar);
      } catch (error) {
        // Manejar el error aquí
      } finally {
        setLoading(false);
      }
    };
    fetchNFTs();
  }, []);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(currentPage * perPage, itemsFiltered?.length); // Corregir aquí
  const paginatedNFTs = itemsFiltered?.slice(startIndex, endIndex);

  return (
    <div>
      {loading && <LoaderBlock />}
      <Box>
        <Text>Total NFTs: {nfts?.length}</Text>
      </Box>
      <NFTFilter onFilter={handleFilter} items={paginatedNFTs} />

      <NFTList nfts={paginatedNFTs} context="userAssets" />
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default NFTCollectionUser;
