import React, { useEffect, useState } from 'react';
import { useGetNfts } from '../../graphql/nft/custom-hooks';
import { Loader } from '../Loader/Loader';
import NFTList from '../ListNFT/NFTList';
import { Pagination } from '../Pagination/Pagination';
import { Box, Text } from '@chakra-ui/react';
import { Error } from '../Error/Error';
import { fetchBNBPrice } from '../../services/fetchBNBPrice';
import { NFTFilter } from '../NFTFilter/NFTFilter';
import { useLazyQuery } from '@apollo/client';
import { GET_NFTS } from '../../graphql/nft/graphql-queries';
import { useMarketplaceNFTContext } from '../../useContext/MarketplaceNFTProvider';
import { useMetamask } from '../../useContext/MetamaskContext';

const NFTCollectionMarketplace = () => {
  const [NFTs, setNFTs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalNfts, setTotalNfts] = useState(0);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [getNFTs, { data, loading, error }] = useLazyQuery(GET_NFTS, {
    fetchPolicy: 'network-only',
  });
  const perPage = 10;

  function handleFilter(filteredItems) {
    setItemsFiltered(filteredItems);
    setCurrentPage(1);
  }
  useEffect(() => {
    getNFTs();
  }, []); // Coloca un array vacío como segundo parámetro para que se ejecute solo una vez al montar el componente

  useEffect(() => {
    if (data) {
      const fetchBnbPrice = async () => {
        try {
          const bnbPrice = await fetchBNBPrice();
          const updatedNfts =
            data?.allNFTs?.nfts.map((nft) => ({
              ...nft,
              priceInDollars: (nft.price * bnbPrice).toFixed(2),
            })) || [];
          setTotalNfts(data?.allNFTs?.totalNfts || 0);
          setNFTs(updatedNfts);
        } catch (error) {
          console.error('Error fetching crypto price:', error);
        }
      };

      fetchBnbPrice();
    }
  }, [data]);

  useEffect(() => {
    setItemsFiltered(NFTs);
  }, [NFTs]);

  useEffect(() => {
    setTotalPages(Math.ceil(itemsFiltered?.length / perPage));
  }, [itemsFiltered, perPage]);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(currentPage * perPage, itemsFiltered?.length); // Corregir aquí
  const paginatedNFTs = itemsFiltered?.slice(startIndex, endIndex);
  if (loading) {
    return (
      <div data-testid="loader-container">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Box
      display="flex"
      p="10px"
      flexDirection="column"
      minHeight="100%"
      position="relative"
      bgColor="#f7f9ff"
    >
      <Box ml="20px">
        <Text fontSize="3xl" fontWeight="bold">
          Explore Players
        </Text>
        <Text>Total NFTs in Marketplace: {totalNfts}</Text>
      </Box>
      <Box p="20px">
        <NFTFilter onFilter={handleFilter} items={NFTs} />
      </Box>

      <Box flex="1" minHeight="0">
        {/* <h1 data-testid="nft-list"></h1> */}
        <NFTList nfts={paginatedNFTs} context="marketplace" />
      </Box>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </Box>
  );
};

export default NFTCollectionMarketplace;
