import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  Button,
  Box,
  InputGroup,
  useDisclosure,
  Select,
  Checkbox,
  FormControl,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormErrorMessage,
  FormLabel,
  List,
  ListItem,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';
import { HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { useGetTeams } from '../../graphql/teams/custom-hooks';
import { useForm } from 'react-hook-form';
import { useMetamask } from '../../useContext/MetamaskContext';
import Fuse from 'fuse.js';
import { filterNFTs } from '../../hooks/useNFTFilter';
import { ButtonFilter } from './ButtonFilter';
import { ModalPriceRange } from './ModalPriceRange';
const selectFilters = [
  {
    label: 'Order',
    children: [
      {
        label: 'asc',
        value: 'asc',
      },
      {
        label: 'desc',
        value: 'desc',
      },
    ],
  },
  {
    label: 'Category',
    children: [
      // {
      //   label: 'price',
      //   value: 'price',
      // },
      {
        label: 'tokenId',
        value: 'tokenId',
      },
      {
        label: 'defense',
        value: 'defense',
      },
      {
        label: 'attack',
        value: 'attack',
      },
      {
        label: 'strength',
        value: 'strength',
      },
    ],
  },
];
export const NFTFilter = ({ onFilter, items }) => {
  const {
    isOpen: isOpenModalPrice,
    onOpen: onOpenModalPrice,
    onClose: onCloseModalPrice,
  } = useDisclosure();
  const {
    isOpen: isOpenModalFilters,
    onOpen: onOpenModalFilters,
    onClose: onCloseModalFilters,
  } = useDisclosure();
  const [suggestions, setSuggestions] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [staticMinPrice, setStatisMinPrice] = useState();
  const [staticMaxPrice, setStaticMaxPrice] = useState();
  const { teams } = useGetTeams();
  const { addressMetamask } = useMetamask();
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      order: '',
      category: '',
      teamName: '',
      searchMyNFTs: null,
      maxPrice: 0,
      minPrice: 0,
    },
    mode: 'onChange',
  });
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  useEffect(() => {
    if (isLargerThan1280) {
      onCloseModalFilters();
    }
  }, [isLargerThan1280]);
  useEffect(() => {
    if (items && items.length > 0) {
      const prices = items.map((item) => item.price);
      const min = Math.min(...prices);
      const max = Math.max(...prices);
      setPriceRange([min, max]);
      setValue('minPrice', min);
      setValue('maxPrice', max);
      setStatisMinPrice(min);
      setStaticMaxPrice(max);
    }
  }, [items]);

  const handleRangeChange = (values) => {
    setPriceRange(values);
  };
  const handleFilter = async () => {
    const filteredNFTs = filterNFTs({
      priceRange,
      filterByRange: !errors.minPrice && !errors.maxPrice,
      nameToFilter: watch('name'),
      category: watch('category'),
      typeOrder: watch('order'),
      teamName: watch('teamName'),
      searchMyNFTs: watch('searchMyNFTs'),
      address: addressMetamask,
      items: items,
    });
    onFilter(filteredNFTs);
  };

  function clearFilter() {
    setValue('name', '');
    setValue('order', '');
    setValue('category', '');
    setValue('teamName', '');
    setValue('minPrice', staticMinPrice);
    setValue('maxPrice', staticMaxPrice);
    setPriceRange([staticMinPrice, staticMaxPrice]);
    setValue('searchMyNFTs', false);
    onFilter(items);
  }
  function handleSuggestions() {
    const name = watch('name');

    if (!name) {
      setSuggestions([]);
      return;
    }

    const fuse = new Fuse(items, {
      keys: ['name'],
      shouldSort: true,
      includeMatches: true,
      threshold: 0.3,
      ignoreLocation: true,
      useExtendedSearch: true,
    });

    const results = fuse.search(name);

    // Extract unique names from the results
    const uniqueNames = [...new Set(results.map((result) => result.item.name))];

    setSuggestions(uniqueNames);
  }

  function handleSelectSuggestions(suggestion) {
    setValue('name', suggestion);
    setSuggestions([]);
  }
  function renderPriceRange() {
    return (
      <>
        <Box>
          <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={2}>
            Price range: ${priceRange[0]} - ${priceRange[1]}
          </Text>
          <Flex justifyContent="space-between">
            <Text>Min. Price: ${staticMinPrice}</Text>
            <Text>Max. Price: ${staticMaxPrice}</Text>
          </Flex>
          <Flex w="100%" alignItems="start">
            <FormControl
              display="grid"
              placeItems="center"
              flex="1"
              isInvalid={errors.minPrice}
            >
              <FormLabel>Min. Price</FormLabel>
              <Input
                {...register('minPrice', {
                  max: {
                    value: staticMaxPrice,
                    message: `Min. Price should be less than ${staticMaxPrice}`,
                  },
                  min: {
                    value: staticMinPrice,
                    message: `Min. Price should be greater than ${staticMinPrice}`,
                  },
                })}
                type="number"
                value={priceRange[0]}
                onChange={(e) => {
                  trigger('minPrice');
                  const newMinValue = e.target.value;
                  setPriceRange((prevPriceRange) => [
                    newMinValue,
                    prevPriceRange[1],
                  ]);
                }}
                min={staticMinPrice}
                max={staticMaxPrice}
                width="48%"
              />
              {errors && errors?.minPrice && (
                <FormErrorMessage>{errors.minPrice.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              margin="0 auto"
              flex="1"
              display="grid"
              placeItems="center"
              isInvalid={errors.maxPrice}
            >
              <FormLabel>Max. Price</FormLabel>
              <Input
                {...register('maxPrice', {
                  max: {
                    value: staticMaxPrice,
                    message: `Min. Price should be less than ${staticMaxPrice}`,
                  },
                  min: {
                    value: staticMinPrice,
                    message: `Min. Price should be greater than ${staticMinPrice}`,
                  },
                })}
                type="number"
                value={priceRange[1]}
                onChange={(e) => {
                  trigger('maxPrice');
                  const newMaxValue = e.target.value;
                  setPriceRange((prevPriceRange) => [
                    prevPriceRange[0],
                    newMaxValue,
                  ]);
                }}
                min={staticMinPrice}
                max={staticMaxPrice}
                width="48%"
              />
              {errors && errors?.maxPrice && (
                <FormErrorMessage>{errors.maxPrice.message}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
          <br />
          <br />
          <RangeSlider
            aria-label={['min', 'max']}
            defaultValue={priceRange}
            value={priceRange}
            min={staticMinPrice}
            max={staticMaxPrice}
            step={0.01}
            onChange={handleRangeChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb bgColor="blue.300" index={0} />
            <RangeSliderThumb bgColor="red.300" index={1} />
          </RangeSlider>
        </Box>
        <Modal></Modal>
      </>
    );
  }

  function renderFilters() {
    return (
      <Flex w="100%" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
        {selectFilters.map((filter) => (
          <Select
            {...register(filter.label.toLowerCase())}
            placeholder={filter.label}
            flex={1}
            key={filter.label}
          >
            {filter.children.map((child) => (
              <option value={child.value} key={child.value}>
                {child.label}
              </option>
            ))}
          </Select>
        ))}
        <Select flex={1} {...register('teamName')} placeholder="Teams">
          {teams?.map((team) => (
            <option value={team} key={team}>
              {team}
            </option>
          ))}
        </Select>

        <Box flex={1}>
          <ModalPriceRange onOpenModalPrice={onOpenModalPrice} />
        </Box>
        <Box flex={1}>
          <ButtonFilter handleFilter={handleFilter} width="100%" />
        </Box>
        <Box flex={1}>
          <Button
            w="100%"
            onClick={clearFilter}
            bgColor="#003399"
            _hover={{ bgColor: '#0240BB' }}
            color="white"
          >
            CLEAR FILTER
          </Button>
        </Box>
      </Flex>
    );
  }
  return (
    <>
      <Box>
        <Flex
          // justifyContent="space-around"
          // alignItems={{ base: 'start', xl: 'flex-end' }}
          // bgColor='red'
          // position='absolute'
          w="100%"
          minH="50px"
          // h="100%"
          maxH={'500px'}
          // h='300px'
          // maxW="1280px"
          spacing={2}
        >
          <Box
            flex={1}
            maxW="600px"
            h="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box
              width="100%"
              maxW="100%"
              rounded="5px"
              display="flex"
              border="0.5px solid #e7eeff"
            >
              <Button
                onClick={handleFilter}
                rounded="none"
                bgColor="transparent"
                position="relative"
                _after={{
                  content: '""',
                  display: 'block',
                  height: '26px',
                  borderLeft: '1px solid #e6e6e6',
                  position: 'absolute',
                  right: '0',
                  top: '6.5px',
                }}
              >
                <SearchIcon />
              </Button>

              <InputGroup>
                <Input
                  {...register('name')}
                  type="search"
                  placeholder="Search by name"
                  _placeholder={{ color: 'purple.500' }}
                  onChange={(ev) => {
                    setValue('name', ev.target.value);
                    handleSuggestions();
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setValue('name', ev.target.value);
                      handleFilter();
                    }
                  }}
                />
              </InputGroup>
            </Box>
            {suggestions.length > 0 && (
              <Box
                // position="absolute"
                // top="100%"
                height="200px"
                overflowY="scroll"
                left="0"
                width="100%"
                bg="white"
                boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                // zIndex="1"
              >
                <List>
                  {suggestions.map((suggestion, index) => (
                    <ListItem
                      key={suggestion}
                      _hover={{
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSelectSuggestions(suggestion)}
                      borderBottom={
                        index !== suggestions.length - 1
                          ? '1px solid #e7eeff'
                          : 'none'
                      }
                    >
                      {suggestion}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>

          <Flex marginTop="10px" marginLeft="20px" alignItems="center" h="100%">
            <HamburgerIcon
              onClick={onOpenModalFilters}
              display={{ base: 'block', xl: 'none' }}
            />
          </Flex>
          <Box
            marginLeft="20px"
            // bg="blue.100"
            flex={1}
            display={{ base: 'none', xl: 'block' }}
          >
            <Flex flexDirection="column" alignItems="center">
              {/* <Text>Filters</Text> */}
              <Box w="100%">{renderFilters()}</Box>
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center" gap="6px" mt="20px">
          <Text m={0}>Search my NFTs</Text>

          <Checkbox
            size="lg"
            m={0}
            {...register('searchMyNFTs')}
            onChange={(ev) => {
              setValue('searchMyNFTs', ev.target.checked);
              handleFilter();
            }}
          />
        </Flex>

        <Box>
          <Modal isOpen={isOpenModalFilters} onClose={onCloseModalFilters}>
            <ModalOverlay>
              <ModalContent>
                <ModalHeader>
                  <Text>Filter</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>{renderFilters()}</ModalBody>
              </ModalContent>
            </ModalOverlay>
          </Modal>
        </Box>
        <Modal isOpen={isOpenModalPrice} onClose={onCloseModalPrice}>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <Text>Price Range</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>{renderPriceRange()}</ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onCloseModalPrice}>
                  Close
                </Button>
                <ButtonFilter handleFilter={handleFilter} />
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </Box>
    </>
  );
};
